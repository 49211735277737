import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Section from '../components/Section'
import Container from '../components/container'
import Image from '../components/image'
import Hero from '../components/Hero/Hero'
// import YouTubePlayer from '../components/YouTubePlayer/YouTubePlayer'
import SkewedOverlay from '../components/SkewedOverlay/SkewedOverlay'
import ComingSoon from '../components/ComingSoon/ComingSoon'

import IFrameWrapper from '../components/embed-website/default'
import Callout from '../components/greener-pom/callout'
import SEO from '../components/seo'

import { GrowShadow, Twinkle } from '../animations/long-shadow-grow'

// import '../scripts/scale-embed.js'

const Title = styled.h1`
  text-transform: uppercase;
  color: #ffffff;
  font-size: 4rem;    
  animation: 
  ${GrowShadow} 2500ms ease 100ms forwards,
  ${Twinkle} 5000ms ease 2600ms infinite;
  width: 100%;
  margin-bottom: 8vh;
  padding-left: 20px;
  z-index: var(--z-lift);
  `

// const Title2 = styled.h1`
//   @import url('https://fonts.googleapis.com/css?family=Yellowtail');
//   /* text-transform: uppercase; */
//   color: rebeccapurple;
//   font-size: 5rem;    
//   font-family: 'Yellowtail', cursive;
//   width: 100%;
//   margin-bottom: 10vh;
//   padding-left: 0px;
//   position: relative;
//   transform: rotate(-5deg);
//   text-shadow:
//       -1px -1px rgba(255, 255, 255, .2),
//       -1px -1px rgba(255, 255, 255, .2),
//       -1px -2px 4px rgba(255, 255, 255, .1),
//       -1px -1px 4px rgba(255, 255, 255, .1),
//       1px 1px purple, 
//       2px 2px purple, 
//       3px 3px purple, 
//       4px 4px purple, 
//       5px 5px purple, 
//       6px 6px purple, 
//       7px 7px purple, 
//       8px 8px purple, 
//       9px 9px purple, 
//       10px 10px purple;
//     z-index: 5;

//     &:before {
//       content: 'Patrick Kleckner';
//       width: 100%;
//       height: 100%;
//       position: absolute;
//       top: 0px;
//       left: 0px;
//       margin: 10px 0 0 10px;
//       text-shadow:
//         1px 1px violet, 
//         2px 2px violet, 
//         3px 3px violet, 
//         4px 4px violet, 
//         5px 5px violet, 
//         6px 6px violet, 
//         7px 7px violet, 
//         8px 8px violet, 
//         9px 9px violet, 
//         10px 10px violet;
//         z-index: -1;
//     }

//     &:after {
//       content: 'Patrick Kleckner';
//       width: 100%;
//       height: 100%;
//       position: absolute;
//       top: 0px;
//       left: 0px;
//       margin: 20px 0 0 20px;
//       text-shadow:
//         1px 1px #ffffff, 
//         2px 2px #ffffff, 
//         3px 3px #ffffff, 
//         4px 4px #ffffff, 
//         5px 5px #ffffff, 
//         6px 6px #ffffff, 
//         7px 7px #ffffff, 
//         8px 8px #ffffff, 
//         9px 9px #ffffff, 
//         10px 10px #ffffff;
//         z-index: -2;
//     }
// `

const SubTitle = styled.h3`
  width: 100%;
  color: #191919;
`

const TitleText = styled.h4`
  width: 100%;
`

export default class Index extends Component {

  render() {
    return (
      // <Layout>
      <>
        <SEO 
          title="Coming Soon!" 
          description="Coming Soon"
          keywords={['pkleckner', 'kleckner', 'killer pom', 'killerpom']} 
        />
        <ComingSoon />
        <Section style={{ height: '100vh', display: 'flex', justifyContent: 'center', flexDirection: 'column', overflow: 'hidden' }}>
          <Container>
             <div style={{ width: `100%`, float: `left`, margin: `0`, textAlign: 'center'}}>
               <Title>Coming Soon</Title>
              {/* <SubTitle>A blazing fast modern site generator for React</SubTitle> */}
               {/* <TitleText>Laborum sint deserunt elit sunt est exercitation nisi reprehenderit. Esse reprehenderit sit aliqua cillum adipisicing velit consequat ad proident cillum. Exercitation tempor proident magna laboris dolore id enim voluptate aliquip occaecat pariatur anim culpa. Anim consectetur eiusmod enim dolore labore laborum. Ex irure enim adipisicing duis Lorem ut culpa proident aliqua. Nostrud laboris enim commodo veniam.</TitleText> */}
             </div>

             {/* <div style={{ width: `48%`, float: `right`, margin: `15px 0 0`}}>
               <IFrameWrapper website="http://localhost:8000" />
             </div> */}
             <div style={{clear: `both`}}></div>
           </Container>
          </Section>
         {/* <Hero bgImage='random'>
           <YouTubePlayer />
           <SkewedOverlay
             skewTopLeft     = '0 0'
             skewTopRight    = 'calc(55% + 10px) 0'
             skewBottomRight = 'calc(45% + 10px) 100%'
             skewBottomLeft  = '0 100%'
             skewCustom      = ''
             bgBaseColor     = '#cc2b5e'
             bgGradientColor = '#111'
             bgGradientAngle = '270'
             bgTexture       = 'https://www.transparenttextures.com/patterns/otis-redding.png'
           />
           <SkewedOverlay
             skewTopLeft     = '0 0'
             skewTopRight    = '55% 0'
             skewBottomRight = '45% 100%'
             skewBottomLeft  = '0 100%'
             skewCustom      = ''
             bgBaseColor     = '#cc2b5e'
             bgGradientColor = '#db8740'
             bgGradientAngle = '270'
             bgTexture       = 'https://www.transparenttextures.com/patterns/otis-redding.png'
           />
           <Container>
             <div style={{ width: `80%`, float: `left`, margin: `0 10%`, textAlign: 'center'}}>
               <Title>Coming Soon</Title>
               <SubTitle>A blazing fast modern site generator for React</SubTitle>
               <TitleText>Laborum sint deserunt elit sunt est exercitation nisi reprehenderit. Esse reprehenderit sit aliqua cillum adipisicing velit consequat ad proident cillum. Exercitation tempor proident magna laboris dolore id enim voluptate aliquip occaecat pariatur anim culpa. Anim consectetur eiusmod enim dolore labore laborum. Ex irure enim adipisicing duis Lorem ut culpa proident aliqua. Nostrud laboris enim commodo veniam.</TitleText>
             </div>

             <div style={{ width: `48%`, float: `right`, margin: `15px 0 0`}}>
               <IFrameWrapper website="http://localhost:8000" />
             </div>
             <div style={{clear: `both`}}></div>
           </Container>
         </Hero>

         <Section>
           <SkewedOverlay
             skewTopLeft     = '0 0'
             skewTopRight    = '45% 0'
             skewBottomRight = '100% 100%'
             skewBottomLeft  = '0 100%'
             skewCustom      = '0 0, 75% 0, 100% 25%, 100% 100%, 25% 100%, 0 75%'
             bgBaseColor     = '#cc2b5e'
             bgGradientColor = '#db8740'
             bgGradientAngle = '45'
             bgTexture       = 'https://www.transparenttextures.com/patterns/otis-redding.png'
           />
           <Container>
             <div>
               <h1>Hi people</h1>
               <p>Welcome to your new Gatsby site.</p>
               <p>Now go build something great.</p>
             </div>

             <div style={{ maxWidth: '300px'}}>
               <Image />
               <Link to="/page-2/">Go to page 2</Link>
             </div>
           </Container>
         </Section>
      
         <Callout />
       </Layout> */}
      </>
    )
  }
}