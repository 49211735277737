import React from 'react'
import styled from 'styled-components'

const Element = styled.div`
    width:                  100%;
    height:                 auto;
    min-height:             100%;
    color:                  var(--color-white);
    z-index:                var(--z-base);
    overflow-x:             hidden;
    display:                flex;
    flex-direction:         column;
    justify-content:        center;
`

const HeroContainer = (props) => {
  return (
    <Element
        {...props}
    >
      { props.children }
    </Element>
  )
}

export default HeroContainer