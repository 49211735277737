import { keyframes } from 'styled-components'

export const GrowShadow = keyframes`
  0% {
    text-shadow: -1px 1px #191919;
  }
  100% {
    text-shadow:
      1px -1px rgba(255, 255, 255, .2),
      1px -1px rgba(255, 255, 255, .2),
      1px -2px 4px rgba(255, 255, 255, .1),
      1px -1px 4px rgba(255, 255, 255, .1),
      -1px 1px #191919, 
      -2px 2px #191919, 
      -3px 3px #191919, 
      -4px 4px #191919, 
      -5px 5px #191919, 
      -6px 6px #191919, 
      -7px 7px #191919, 
      -8px 8px #191919, 
      -9px 9px #191919, 
      -10px 10px #191919, 
      -11px 11px #191919, 
      -12px 12px #191919, 
      -13px 13px #191919, 
      -14px 14px #191919, 
      -15px 15px #191919, 
      -16px 16px #191919, 
      -17px 17px #191919, 
      -18px 18px #191919, 
      -19px 19px #191919, 
      -20px 20px #191919;
  }
`

export const Twinkle = keyframes`
  0% {
    text-shadow:
      1px -1px rgba(255, 255, 255, .2),
      1px -1px rgba(255, 255, 255, .2),
      1px -2px 4px rgba(255, 255, 255, .1),
      1px -1px 4px rgba(255, 255, 255, .1),
      -1px 1px #191919, 
      -2px 2px #191919, 
      -3px 3px #191919, 
      -4px 4px #191919, 
      -5px 5px #191919, 
      -6px 6px #191919, 
      -7px 7px #191919, 
      -8px 8px #191919, 
      -9px 9px #191919, 
      -10px 10px #191919, 
      -11px 11px #191919, 
      -12px 12px #191919, 
      -13px 13px #191919, 
      -14px 14px #191919, 
      -15px 15px #191919, 
      -16px 16px #191919, 
      -17px 17px #191919, 
      -18px 18px #191919, 
      -19px 19px #191919, 
      -20px 20px #191919;
  }
  10% {
    text-shadow:
      1px -1px rgba(255, 255, 255, .2),
      1px -1px rgba(255, 255, 255, .2),
      1px -2px 4px rgba(255, 255, 255, .1),
      1px -1px 4px rgba(255, 255, 255, .1),
      -1px 1px #191919, 
      -2px 2px #191919, 
      -3px 3px #191919, 
      -4px 4px #191919, 
      -5px 5px #191919, 
      -6px 6px #191919, 
      -7px 7px #191919, 
      -8px 8px #191919, 
      -9px 9px #191919, 
      -10px 10px #191919, 
      -11px 11px #191919, 
      -12px 12px #191919, 
      -13px 13px #191919, 
      -14px 14px #191919, 
      -15px 15px #191919, 
      -16px 16px #191919, 
      -17px 17px #191919, 
      -18px 18px #191919, 
      -19px 19px #191919, 
      -20px 20px #191919;
  }
  25% {
    text-shadow:
      1px -1px 2px rgba(255, 255, 255, .4),
      1px -1px 0px rgba(255, 255, 255, .25),
      1px -2px 4px rgba(255, 255, 255, .1),
      1px -1px 4px rgba(255, 255, 255, .1),
      -1px 1px #191919, 
      -2px 2px #191919, 
      -3px 3px #191919, 
      -4px 4px #191919, 
      -5px 5px #191919, 
      -6px 6px #191919, 
      -7px 7px #191919, 
      -8px 8px #191919, 
      -9px 9px #191919, 
      -10px 10px #191919, 
      -11px 11px #191919, 
      -12px 12px #191919, 
      -13px 13px #191919, 
      -14px 14px #191919, 
      -15px 15px #191919, 
      -16px 16px #191919, 
      -17px 17px #191919, 
      -18px 18px #191919, 
      -19px 19px #191919, 
      -20px 20px #191919;
  }
  40% {
    text-shadow:
      1px -1px rgba(255, 255, 255, .2),
      1px -1px rgba(255, 255, 255, .2),
      1px -2px 4px rgba(255, 255, 255, .1),
      1px -1px 4px rgba(255, 255, 255, .1),
      -1px 1px #191919, 
      -2px 2px #191919, 
      -3px 3px #191919, 
      -4px 4px #191919, 
      -5px 5px #191919, 
      -6px 6px #191919, 
      -7px 7px #191919, 
      -8px 8px #191919, 
      -9px 9px #191919, 
      -10px 10px #191919, 
      -11px 11px #191919, 
      -12px 12px #191919, 
      -13px 13px #191919, 
      -14px 14px #191919, 
      -15px 15px #191919, 
      -16px 16px #191919, 
      -17px 17px #191919, 
      -18px 18px #191919, 
      -19px 19px #191919, 
      -20px 20px #191919;
  }
  100% {
    text-shadow:
      1px -1px rgba(255, 255, 255, .2),
      1px -1px rgba(255, 255, 255, .2),
      1px -2px 4px rgba(255, 255, 255, .1),
      1px -1px 4px rgba(255, 255, 255, .1),
      -1px 1px #191919, 
      -2px 2px #191919, 
      -3px 3px #191919, 
      -4px 4px #191919, 
      -5px 5px #191919, 
      -6px 6px #191919, 
      -7px 7px #191919, 
      -8px 8px #191919, 
      -9px 9px #191919, 
      -10px 10px #191919, 
      -11px 11px #191919, 
      -12px 12px #191919, 
      -13px 13px #191919, 
      -14px 14px #191919, 
      -15px 15px #191919, 
      -16px 16px #191919, 
      -17px 17px #191919, 
      -18px 18px #191919, 
      -19px 19px #191919, 
      -20px 20px #191919;
  }
`

export default (GrowShadow, Twinkle)