import React from 'react'
import styled from 'styled-components'
// import { Link } from 'gatsby'
import Container from '../container'
import BgImage from '../../images/nature-forest-lake-001.jpg'

const Callout = styled.section`
     height: auto;
     min-height: 50vh;
     width: 100%;
     background: rgba(17, 78, 8, 1);
     background-image: url(${BgImage});
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center center;
     padding: 12.5px 0;
     position: relative;
     overflow: hidden;
     z-index: -1;

     &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(30deg, rgba(86, 171, 47, 1) 25%, rgba(168, 224, 99, .7) 100%);
          /* background: linear-gradient(30deg, #34e89e, #0f3443); */
          /* background: linear-gradient(30deg, #45b649, #dce35b); */
          /* background: linear-gradient(30deg, #45a247, #283c86); */
          /* background: linear-gradient(30deg, #96c93d, #00b09b); */
          /* background: linear-gradient(30deg, #799f0c, #acbb78); */
          /* background: linear-gradient(30deg, #215f00, #e4e4d9); */
          opacity: .9;
          z-index: -1;
     }
`

export default () => (
     <Callout>
          <Container>
               <h1>Green Section</h1>
          </Container>
     </Callout>
)