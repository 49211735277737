import React, { Component } from 'react'
import styled from 'styled-components'

const SkewedBG = styled.div`
    background: 
      ${props =>
        props.bgBaseColor ? `rgba(${props.bgBaseColor}, .7)` :
        `rgba(113, 187, 51, 0.7)`
      };
    background-image:
      ${props =>
        props.bgGradientColor  &&  props.bgTexture  &&  props.bgGradientAngle   ? `
          url("${props.bgTexture}"), 
          linear-gradient(
            ${props.bgGradientAngle}deg, 
            rgba(${props.bgGradientColor}, 1) 50%, 
            rgba(${props.bgGradientColor}, 0) 100%
          )` :
        props.bgGradientColor  &&  props.bgTexture         ? `
          url("${props.bgTexture}"), 
          linear-gradient(
            0deg, 
            rgba(${props.bgGradientColor}, 1) 50%, 
            rgba(${props.bgGradientColor}, 0) 100%
          )` :
        props.bgGradientColor  &&  props.bgGradientAngle   ? `
          linear-gradient(
            ${props.bgGradientAngle}deg, 
            rgba(${props.bgGradientColor}, 1) 50%, 
            rgba(${props.bgGradientColor}, 0) 100%
          )` :
        props.bgGradientColor  ? `
          linear-gradient(
            0deg, 
            rgba(${props.bgGradientColor}, 1) 50%, 
            rgba(${props.bgGradientColor}, 0) 100%
          )` :
        props.bgTexture  &&  props.bgGradientAngle  ? `
          url("${props.bgTexture}"),  
          linear-gradient(
            ${props.bgGradientAngle}deg, 
            rgba(65, 193, 245, 1) 50%, 
            rgba(65, 193, 245, 0) 100%
          )` :
        props.bgTexture  ? `
          url("${props.bgTexture}"),  
          linear-gradient(
            0deg, 
            rgba(65, 193, 245, 1) 50%, 
            rgba(65, 193, 245, 0) 100%
          )` :
        props.bgGradientAngle  ? `  
          linear-gradient(
            ${props.bgGradientAngle}deg, 
            rgba(65, 193, 245, 1) 50%, 
            rgba(65, 193, 245, 0) 100%
          )` :
        `url("https://www.transparenttextures.com/patterns/otis-redding.png"), linear-gradient(-70deg, rgba(65, 193, 245, 1) 50%, rgba(65, 193, 245, 0) 100%)`
      };
    height: 100%;
    width: 50%;
    top: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    z-index: var(--z-base);

    @supports (
     -webkit-clip-path:
          ${props => 
               props.skewCustom  ?
                    `polygon(
                         ${props.skewCustom}
                    )`  :
               (props.skewTopLeft && props.skewTopRight && props.skewBottomRight && props.skewBottomLeft)  ? 
                   `polygon(
                         ${props.skewTopLeft},
                         ${props.skewTopRight},
                         ${props.skewBottomRight},
                         ${props.skewBottomLeft}
                    )`  :
               (props.skewTopLeft && props.skewTopRight && props.skewBottomRight) ? 
                    `polygon(
                         ${props.skewTopLeft},
                         ${props.skewTopRight},
                         ${props.skewBottomRight},
                         0 100%
                    )`  :
               (props.skewTopLeft && props.skewTopRight && props.skewBottomLeft)  ? 
                    `polygon(
                         ${props.skewTopLeft},
                         ${props.skewTopRight},
                         45% 100%,
                         ${props.skewBottomLeft}
                    )`  :
               (props.skewTopLeft && props.skewBottomRight && props.skewBottomLeft)  ? 
                    `polygon(
                         ${props.skewTopLeft},
                         55% 0,
                         ${props.skewBottomRight},
                         ${props.skewBottomLeft}
                    )`  :
               (props.skewTopRight && props.skewBottomRight && props.skewBottomLeft)  ? 
                    `polygon(
                         0 0,
                         ${props.skewTopRight},
                         ${props.skewBottomRight},
                         ${props.skewBottomLeft}
                    )`  :
               (props.skewTopLeft && props.skewTopRight)  ? 
                    `polygon(
                         ${props.skewTopLeft},
                         ${props.skewTopRight},
                         45% 100%,
                         0 100%
                    )`  :
               (props.skewTopLeft && props.skewBottomRight)  ? 
                    `polygon(
                         ${props.skewTopLeft},
                         55% 0,
                         ${props.skewBottomRight},
                         0 100%
                    )`  :
               (props.skewTopLeft && props.skewBottomLeft)  ? 
                    `polygon(
                         ${props.skewTopLeft},
                         55% 0,
                         45% 100%,
                         ${props.skewBottomLeft}
                    )`  :
               `polygon(
                    0 0,
                    55% 0,
                    45% 100%,
                    0 100%
               )`
          }
     )
     {
          width: 100vw;
          clip-path: 
          ${props => 
               props.skewCustom  ?
                    `polygon(
                         ${props.skewCustom}
                    )`  :
               (props.skewTopLeft && props.skewTopRight && props.skewBottomRight && props.skewBottomLeft)  ? 
                   `polygon(
                         ${props.skewTopLeft},
                         ${props.skewTopRight},
                         ${props.skewBottomRight},
                         ${props.skewBottomLeft}
                    )`  :
               (props.skewTopLeft && props.skewTopRight && props.skewBottomRight) ? 
                    `polygon(
                         ${props.skewTopLeft},
                         ${props.skewTopRight},
                         ${props.skewBottomRight},
                         0 100%
                    )`  :
               (props.skewTopLeft && props.skewTopRight && props.skewBottomLeft)  ? 
                    `polygon(
                         ${props.skewTopLeft},
                         ${props.skewTopRight},
                         45% 100%,
                         ${props.skewBottomLeft}
                    )`  :
               (props.skewTopLeft && props.skewBottomRight && props.skewBottomLeft)  ? 
                    `polygon(
                         ${props.skewTopLeft},
                         55% 0,
                         ${props.skewBottomRight},
                         ${props.skewBottomLeft}
                    )`  :
               (props.skewTopRight && props.skewBottomRight && props.skewBottomLeft)  ? 
                    `polygon(
                         0 0,
                         ${props.skewTopRight},
                         ${props.skewBottomRight},
                         ${props.skewBottomLeft}
                    )`  :
               (props.skewTopLeft && props.skewTopRight)  ? 
                    `polygon(
                         ${props.skewTopLeft},
                         ${props.skewTopRight},
                         45% 100%,
                         0 100%
                    )`  :
               (props.skewTopLeft && props.skewBottomRight)  ? 
                    `polygon(
                         ${props.skewTopLeft},
                         55% 0,
                         ${props.skewBottomRight},
                         0 100%
                    )`  :
               (props.skewTopLeft && props.skewBottomLeft)  ? 
                    `polygon(
                         ${props.skewTopLeft},
                         55% 0,
                         45% 100%,
                         ${props.skewBottomLeft}
                    )`  :
               `polygon(
                    0 0,
                    55% 0,
                    45% 100%,
                    0 100%
               )`
          };
     }

     /* @supports (-webkit-clip-path: 
    polygon(
        0 0, 
        55% 0, 
        45% 100%, 
        0 100%)
        ) {
      width: 100vw;
      clip-path: polygon(
        0 0, 
        55% 0, 
        45% 100%, 
        0 100%);
    } */
`

export default class SkewedOverlay extends Component {
     constructor(props) {
       super(props)
   
       this.hexToRgb           =   this.hexToRgb.bind(this)
     }

     hexToRgb(hex){
          if (hex !== '') {
               var c;
               if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
                    c = hex.substring(1).split('');
                    if(c.length === 3){
                         c= [c[0], c[0], c[1], c[1], c[2], c[2]];
                    }
                    c = '0x' + c.join('');
                    return '' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + '';
               }
               else if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                    c = hex;
                    return c;
               }
               else {
                    throw new Error('Invalid HEX Value');
               }
          }
     }
   
     render() {
       return (
          <>
               <SkewedBG 
                    {...this.props}
                    bgBaseColor = {this.hexToRgb(this.props.bgBaseColor)}
                    bgGradientColor = {this.hexToRgb(this.props.bgGradientColor)}
               >
               </SkewedBG>
          </>
       )
     }
   }