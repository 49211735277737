import React from 'react'
import styled, { keyframes } from 'styled-components'

const GradientSpin = keyframes`
     from {
          transform: rotate(0deg);
     }
     to {
          transform: rotate(360deg);
     }
`

const BeforeOpacity = keyframes`
  0% {
    opacity: 0;
  }
  33.3% {
    opacity: 1;
  }
  66.6% {
       opacity: 0;
  }
  100% {
       opacity: 0;
  }
`

const AfterOpacity = keyframes`
  0% {
    opacity: 0;
  }
  33.3% {
    opacity: 0;
  }
  66.6% {
       opacity: 1;
  }
  100% {
       opacity: 0;
  }
`

const Elem = styled.section`
     width: 100vw;
     height: 100vh;
     background-image: 
          url("https://www.transparenttextures.com/patterns/otis-redding.png"),
          linear-gradient(
               -70deg,
               /* rgba(69, 104, 220, .7),  */
               #db8740 50%,
               #cc2b5e 100%
          );
     position: absolute;
     opacity: 1;

     &:before, &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
     }

     &:before {
          background-image: 
               url("https://www.transparenttextures.com/patterns/otis-redding.png"),
               linear-gradient(
                    -70deg,
                    rgba(69, 104, 220, 1), 
                    rgba(176, 106, 179, 1)
               );
          animation: ${BeforeOpacity} 50000ms ease infinite; 
     }

     &:after {
          background-image: 
               url("https://www.transparenttextures.com/patterns/otis-redding.png"),
               linear-gradient(
                    -70deg,
                    #43C6AC, 
                    #F8FFAE
               );
          animation: ${AfterOpacity} 50000ms ease infinite;
     }
`

const ComingSoon = (props) => {
    return (
      <Elem {...props}>
        { props.children }
      </Elem>
    )
}

export default ComingSoon