import React from 'react'
import styled from 'styled-components'
import BGImage from '../../images/prime-bg.jpg'

function generateRandomNumber() {
    let random_number = Math.random() * (36 - 1) + 1

    random_number = Math.floor(random_number)

    if (random_number < 10) {
        random_number = '0' + random_number
    }

    return random_number
}

const HeroSection = styled.section`
    width: 100%;
    height: 
        ${props =>
            props.screenHeight ? props.screenHeight : `100vh`
        };
    background-image:
        ${props =>
            props.bgImage  ===  `random`  ?  `url("http://cdn.killerpom.com/images/full-screen-images/00${generateRandomNumber()}.jpg")`  :
            props.bgImage                 ?  `url("${props.bgImage}")`  : 
            `url("${BGImage}")`
        };
    background-size:        cover;
    background-position:    center center;
    z-index:                var(--z-sub);
    overflow-x:             hidden;

    &:before {
        content:            '';
        top:                0;
        left:               0;
        width:              100%;
        height:             100%;
        background-color:   
            ${props =>
                props.overlayColor      ?       props.overlayColor    :
                `var(--color-black)`
            };
        opacity:   
            ${props =>
                props.overlayOpacity
                ? `${ props.overlayOpacity / 100}`
                : `0.6`
            };
        display:
            ${props =>
                props.overlay  ?  'block'  :  'none'
            };
        position:           absolute;
        z-index:            var(--z-sub);
    }
`

const HeroBackground = (props) => {
    return (
      <HeroSection {...props}>
        {props.children}
      </HeroSection>
    )
}

export default HeroBackground