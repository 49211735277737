import React from 'react'
import styled, { keyframes } from 'styled-components'

import $ from 'jquery'

if (typeof window !== `undefined`) {
  function runAfterElementExists(jquery_selector,callback){
       window.setInterval(function() {
            if ($(jquery_selector).length) {
                 callback();
            }
       }, 250);
  }

  runAfterElementExists(".embed", function() {
       var scale = $('.iframe-wrapper').width() / 1920;
       var resizeTimer;

       $('.embed').css({transform: `scale(${ scale })`});

       $(window).on('resize', function(e) {

            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {

                 scale = $('.iframe-wrapper').width() / 1920;
                 
                 $('.embed').css({transform: `scale(${ scale })`});
                      
            }, 250);
       });
  });
}

export const LoadEmbed = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const IFrameWrapper = styled.div`
  background-color: #c5c5c5;
  overflow: hidden;
  width: 100%;
  height: 0;
  left: 0;
  box-shadow: 3px 8px 7px -4px rgba(0,0,0,.2);
  padding-top: 56.25%;
  position: relative;

  span {
    height: 100%;
    width: 100%;
    color: #6e6e6e;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    animation: ${LoadEmbed} 2500ms ease 0ms infinite;

    &:before {
      content: '<';
      position: absolute;
      top: 0;
      left: calc(50% - 50px);
      color: #6e6e6e;
      opacity: 0;
      animation: ${LoadEmbed} 2500ms ease 800ms infinite;
    }

    &:after {
      content: '>';
      position: absolute;
      top: 0;
      right: calc(50% - 50px);
      color: #6e6e6e;
      opacity: 0;
      animation: ${LoadEmbed} 2500ms ease 800ms infinite;
    }
  }

  iframe, embed {
    border: 0;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    width: 1920px;
    height: 1080px;
    border: 0;
    transform: scale(1);
    transform-origin: 0 0;
  }
`

export default (props) => (
     <IFrameWrapper className="iframe-wrapper">
          <span>/</span>
          {/* <embed className="embed" src="https://killerpom.com"></embed> */}
          <embed className="embed" src={props.website}></embed>
     </IFrameWrapper>
)